@import url(https://unpkg.com/ionicons@4.2.2/dist/css/ionicons.min.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

ul {
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0;
  -webkit-padding-start: 0;
          padding-inline-start: 0; }

nav.responsive-toolbar {
  background: #333333;
  height: 60px;
  width: 100%;
  position: fixed;
  box-shadow: 1px 2px 2px 1px #cccccc;
  z-index: 9; }
  nav.responsive-toolbar ~ * {
    padding-top: 60px; }
  nav.responsive-toolbar ul {
    background: #333333;
    display: flex;
    -webkit-margin-before: 0;
            margin-block-start: 0;
    -webkit-margin-after: 0;
            margin-block-end: 0;
    -webkit-padding-start: 0;
            padding-inline-start: 0;
    height: 100%;
    align-items: center;
    box-shadow: 1px 2px 2px 1px #cccccc; }
    nav.responsive-toolbar ul figure {
      -webkit-margin-before: 0;
              margin-block-start: 0;
      -webkit-margin-after: 0;
              margin-block-end: 0;
      -webkit-margin-start: 2px;
              margin-inline-start: 2px;
      -webkit-margin-end: 0;
              margin-inline-end: 0;
      cursor: pointer; }
    nav.responsive-toolbar ul li {
      list-style-type: none;
      padding: 10px 20px; }
      nav.responsive-toolbar ul li .active {
        background: #999; }
    nav.responsive-toolbar ul a {
      color: #ffffff;
      text-decoration: none;
      display: flex;
      flex-direction: row-reverse;
      align-items: center; }
    nav.responsive-toolbar ul i {
      margin-right: 5px;
      font-size: 20px; }

@media screen and (max-width: 759px) {
  nav.responsive-toolbar ~ * {
    margin-left: 60px; }
  nav.responsive-toolbar ul {
    height: 100%;
    width: 300px;
    left: -240px;
    position: fixed;
    top: 60px;
    flex-direction: column;
    transition: 300ms ease all; }
    nav.responsive-toolbar ul.active {
      left: 0px; }
    nav.responsive-toolbar ul figure {
      position: fixed;
      left: 9px;
      top: 10px; }
    nav.responsive-toolbar ul li {
      width: 100%;
      padding-left: 0;
      padding-right: 0; }
    nav.responsive-toolbar ul a {
      flex-direction: row;
      justify-content: space-between;
      margin-left: 20px;
      margin-right: 16px; } }

@media screen and (max-width: 480px) {
  nav.responsive-toolbar ~ * {
    margin-left: 0; }
  nav.responsive-toolbar ul {
    left: -300px; } }

.dashboard {
  margin-top: 20px; }

.myBtn {
  padding: 20px;
  background-color: lightblue;
  color: white;
  font-size: 20px; }

.dashboard-selector {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(315px, 1fr));
  margin: 20px; }
  .dashboard-selector .dashboard-card {
    height: 225px;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    text-decoration: none; }
    .dashboard-selector .dashboard-card:hover {
      background: #f9f9f9;
      box-shadow: 2px 2px 2px #888; }
    .dashboard-selector .dashboard-card .dash-title {
      border-bottom: 1px solid #ccc;
      display: flex;
      align-items: center;
      color: #444;
      padding: 15px;
      margin: 0; }
    .dashboard-selector .dashboard-card .dash-description {
      color: #777;
      text-align: left;
      padding: 15px;
      margin: 0; }

section.chart {
  border-top: 1px solid #cccccc;
  padding-bottom: 15px;
  margin-left: 5px;
  margin-right: 5px;
  min-width: 350px;
  margin-bottom: 20px;
  float: left; }
  section.chart .chart-header-container {
    color: #333333;
    display: flex;
    text-align: left;
    justify-content: space-between;
    padding: 10px 20px; }
    section.chart .chart-header-container i {
      cursor: pointer;
      font-size: 20px; }
      section.chart .chart-header-container i:hover {
        color: #2d8cf0; }
  section.chart .options-container {
    position: relative; }
  section.chart div.chart {
    width: 100%;
    height: 100%; }

.dropdown {
  border: 1px solid #cccccc;
  box-shadow: 1px 2px 1px 2px #cccccc;
  min-width: 200px;
  min-height: 100px;
  position: absolute;
  background: #ffffff;
  z-index: 10; }

.options-list li {
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  color: #666666; }
  .options-list li:hover {
    cursor: pointer;
    color: #2d8cf0; }
  .options-list li i {
    font-size: 20px; }

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

